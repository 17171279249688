import React from 'react';
import Layout from '../components/Layout/Layout';
import Seo from '../components/Layout/Seo';

export default function ErrorPage() {

  return (
    <Layout>
      <Seo title="Error" />
    </Layout>
  );
}
